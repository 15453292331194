import * as React from 'react';
import { useState, useEffect } from 'react';
import { Loader } from '../../components/Loader';
import { useObjectVal } from 'react-firebase-hooks/database';
import { fuzzyTextFilterFnExp } from '../accounts/AccountsContainer';
import { DefaultColumnFilter } from '../../components/table/DefaultFilter';
import { useFilters, useSortBy, useTable, usePagination } from 'react-table';
import { OptinsTable } from './OptinsTable';
import { Button, Col, Collapse, Row, Alert } from 'reactstrap';
import moment from 'moment';
import { ColumnsSelector } from '../../components/table/ColumnsSelector';
import { getDAMApp, getIFBApp } from '../../services/firebase';
import { logger } from '../../logging';
import {
  DDTableFilters,
  countries as getCountries,
} from '../../components/DDTableFilters';
import { getAuth } from 'firebase/auth';
import { ref } from '../../utils/firebase';
const R = require('ramda');

const pagesStyles = require('../pages.css');
const optinsStyles = require('./optins.css');

const searchQueryLabels = {
  accountName: 'Account Name',
  name: 'Name',
  key: 'Key',
};

export const Optins = ({ enumerations, community_account_id = null }) => {
  const damApp = getDAMApp();
  const ifbApp = getIFBApp();
  const damAuth = getAuth(damApp);
  const TABLE_NAME = 'optins';

  const [values, setValues] = useState([]);
  const [filteredValues, setFilteredValues] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('name');
  const [searchValue, setSearchValue] = useState('');
  const [searchType, setSearchType] = useState('exact');

  logger.debug(`[Optins] community_account_id: ${community_account_id}`);

  const [loading, setLoading] = useState(true);
  const [isStaggeredLoading, setIsStaggeredLoading] = useState(false);

  const [isFiltersSelectOpen, setIsFiltersSelectOpen] = useState(true);
  const [isColumnsSelectOpen, setIsColumnsSelectOpen] = useState(false);
  const uid = damAuth.currentUser.uid;
  const userColsRef = ref(`user_preferences/${uid}/columns/${TABLE_NAME}`);
  const [userCols, userColsLoading, userColsError] = useObjectVal(
    userColsRef as any
  );

  const textwordCategories = enumerations.enumerations.textwordCategories;
  const addressbookCategories = enumerations.enumerations.addressbookCategories;
  const whatsappCategories = enumerations.enumerations.whatsappCategories;
  const categories = {
    ...addressbookCategories,
    ...textwordCategories,
    ...whatsappCategories,
  };

  useEffect(() => {
    (async () => {
      const countryList = (await getCountries()).map((x) => {
        x.id = String(x.id).toLowerCase();
        return x;
      });
      setCountryList(countryList);
    })();
  }, []);

  // when filteredValues changes
  // and there are no values yet
  // put values into the correct shape + update state with mapped values
  useEffect(() => {
    logger.debug(`[Optins] UseEffect called`, filteredValues);
    const optins = !filteredValues
      ? []
      : Object.keys(filteredValues).filter((k) => {
          return filteredValues[k];
        });

    setValues(
      optins.map((k) => {
        const value = filteredValues[k];
        const reducedData = {
          key: value.key,
          active: value.active,
          country: value.country,
          channel: value.channel,
          vendor: value.vendor,
          category: value.category,
          brandId: value.brandId,
          name: value.name,
          tag: value.tag,
          target_id: value.target_id,
          accountName: value.accountName,
          country_channel_category_brandId_tag:
            value.country_channel_category_brandId_tag,
          created: value.created,
          modified: value.modified,
        };
        return { ...reducedData };
      })
    );
  }, [filteredValues]); // when filteredValues changes

  const columns = React.useMemo(() => {
    const cols = [
      {
        Header: 'Key',
        accessor: 'key',
        id: 'key',
      },
      {
        Header: 'Name',
        accessor: 'name',
        id: 'name',
      },
      {
        Header: 'Active',
        accessor: (row) => {
          if (row.active) {
            return `${row.active}`;
          }

          return 'false';
        },
        id: 'active',
      },
      {
        Header: 'Country',
        accessor: 'country',
        id: 'country',
      },
      {
        Header: 'Channel',
        accessor: 'channel',
        id: 'channel',
      },
      {
        Header: 'Vendor',
        accessor: 'vendor',
        id: 'vendor',
      },
      {
        Header: 'Category',
        accessor: 'category',
        id: 'category',
      },
      {
        Header: 'Brand Id',
        accessor: 'brandId',
        id: 'brandId',
      },
      {
        Header: 'Tag',
        accessor: 'tag',
        id: 'tag',
      },
      {
        Header: 'Target Id',
        accessor: 'target_id',
        id: 'target_id',
      },
      {
        Header: 'Account Name',
        accessor: 'accountName',
        id: 'accountName',
      },
      {
        Header: 'Unique Check Index',
        accessor: 'country_channel_category_brandId_tag',
        id: 'country_channel_category_brandId_tag',
      },
      {
        Header: 'Created',
        accessor: (row) => {
          if (row.created) {
            return moment
              .unix(parseInt(row.created))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'created',
      },
      {
        Header: 'Modified',
        accessor: (row) => {
          if (row.modified) {
            return moment
              .unix(parseInt(row.modified))
              .format('YYYY-MM-DD HH:mm:ss');
          }

          return '';
        },
        id: 'modified',
      },
    ];

    return cols;
  }, []);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFnExp,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // memoize values - the heavy lifting is now done in the above useEffect
  const records = React.useMemo(() => values, [values]);

  let hiddenColumns: any = R.keys(
    R.pickBy((val, key) => val === false, userCols)
  );

  const filters = [];
  if (community_account_id) {
    filters.push({ id: 'vendor', value: 'community' });
  }

  const tableData = useTable(
    {
      columns,
      data: filteredValues,
      filterTypes,
      defaultColumn,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'created',
            desc: true,
          },
        ],
        filters: filters,
        pageSize: 20,
        pageIndex: 0,
      },
      autoResetHiddenColumns: false,
    } as any,
    useFilters,
    useSortBy,
    usePagination
  ) as any;

  React.useEffect(() => {
    hiddenColumns = R.keys(R.pickBy((val, key) => val === false, userCols));
    tableData.setHiddenColumns(hiddenColumns);
  }, [userCols]);

  if (userColsError) {
    return <div>Error loading cols</div>;
  }

  const toggleColumnsSelect = () =>
    setIsColumnsSelectOpen(!isColumnsSelectOpen);
  const toggleFiltersSelect = () =>
    setIsFiltersSelectOpen(!isFiltersSelectOpen);
  logger.debug('[Optins] about to render');
  return (
    <div className={pagesStyles.fullHeight}>
      <Row>
        <Col sm={{ size: 12 }} className={optinsStyles.columnsButton}>
          <Button
            onClick={toggleColumnsSelect}
            className={optinsStyles.rowButton}
          >
            Columns
          </Button>
          <Button
            onClick={toggleFiltersSelect}
            className={optinsStyles.rowButton}
          >
            Filters
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={5}></Col>
      </Row>
      <Row>
        <Col className={pagesStyles.divider}></Col>
      </Row>
      <Row>
        <Col sm={5}>
          <DDTableFilters
            staggeredLoading
            setIsStaggeredLoading={setIsStaggeredLoading}
            path="optins"
            db="ifb"
            setLoading={setLoading}
            title="Find Optins rows"
            rowsToExport={tableData.rows.map((row) => row.values) || []}
            setFilteredValues={setFilteredValues}
            customQuery={
              community_account_id
                ? {
                    query: 'target_id',
                    value: community_account_id,
                  }
                : undefined
            }
            filters={
              community_account_id
                ? [
                    {
                      primary: true,
                      type: 'search',
                      query: 'optins_search',
                      customHandler: false,
                      submitable: true,
                      labels: {
                        placeholder: '',
                      },
                      options: [
                        {
                          id: 'name',
                          label: 'Name',
                        },
                        {
                          id: 'key',
                          label: 'Key',
                        },
                        {
                          id: 'target_id',
                          label: 'Target ID',
                        },
                        {
                          toggle: true,
                          id: 'channel',
                          label: 'Channel',

                          value: [
                            { id: 'sms', label: 'SMS' },
                            { id: 'whatsapp', label: 'Whatsapp' },
                            { id: 'email', label: 'Email' },
                          ],
                        },
                      ],
                    },
                  ]
                : [
                    {
                      primary: true,
                      type: 'search',
                      query: 'optins_search',
                      customHandler: false,
                      submitable: true,
                      labels: {
                        placeholder: '',
                      },
                      options: [
                        {
                          id: 'name',
                          label: 'Name',
                        },
                        {
                          id: 'key',
                          label: 'Key',
                        },
                        {
                          id: 'target_id',
                          label: 'Target ID',
                        },
                        {
                          id: 'accountName',
                          label: 'Account Name',
                        },
                        {
                          toggle: true,
                          id: 'vendor',
                          label: 'Vendor',
                          value: [
                            { id: 'slicktext', label: 'SlickText' },
                            { id: 'community', label: 'Community' },
                            { id: 'dotdigital', label: 'Dotdigital' },
                          ],
                        },
                        {
                          toggle: true,
                          id: 'country',
                          label: 'Country',
                          value: countryList,
                        },
                        {
                          toggle: true,
                          id: 'channel',
                          label: 'Channel',

                          value: [
                            { id: 'sms', label: 'SMS' },
                            { id: 'whatsapp', label: 'Whatsapp' },
                            { id: 'email', label: 'Email' },
                          ],
                        },
                        {
                          toggle: true,
                          id: 'category',
                          label: 'Category',

                          value: Object.values(categories).map((x: any) => {
                            return { id: x.value, label: x.label };
                          }),
                        },
                      ],
                    },
                  ]
            }
          />
          <Row>
            <Col className={pagesStyles.divider}></Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Collapse isOpen={isColumnsSelectOpen}>
            <ColumnsSelector
              columns={columns}
              userCols={userCols}
              tableName={TABLE_NAME}
            />
          </Collapse>
        </Col>
      </Row>

      {!loading && filteredValues.length === 0 && (
        <Row>
          <Col sm={{ size: 12 }}>
            <Alert color="warning">
              No entries found, initiate a search to load data.
            </Alert>
          </Col>
        </Row>
      )}
      {isStaggeredLoading && (
        <Row>
          <Col sm={{ size: 12 }}>
            <Alert color="info">
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <img
                  src="/images/loading.gif"
                  className="loading"
                  style={{ height: 40 }}
                />
                <span>Loading more data...</span>
              </span>
            </Alert>
          </Col>
        </Row>
      )}
      <Row className={pagesStyles.fullHeight}>
        <Col sm={{ size: 12 }} className={pagesStyles.fullHeight}>
          {loading || userColsLoading ? (
            <Loader loading={true} />
          ) : (
            <>
              <h5>
                Currently looking at: {searchQueryLabels[searchQuery]} |{' '}
                {searchValue}
              </h5>
              <OptinsTable
                {...tableData}
                community_account_id={community_account_id}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};
